const colorArr = [
  "#3ac48a",
  "orange",
  "#ffa16c",
  "#697789",
  "#329aff",
  "#4ed6e7",
  "#ff6378",
  "#893037",
  "#606371",
  "#E55B5B",
  "#2F5090",
  "#196EEF",
  "#5C33D1",
  "#9B51E0",
];

export default colorArr;
